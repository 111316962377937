export const useAuthService = function() {
  const apiFetch = useSanctumClient();
  const { logout, login } = useSanctumAuth();
  return {
    resetPassword: async function(values, token) {
      return apiFetch(authUrl.resetPassword, {
        method: 'POST',
        body: {
          email: values.email,
          password: values.password,
          password_confirmation: values.confirm_password,
          token
        },
      });

    },
    sendPasswordResetLink: async function(values) {
      return apiFetch(authUrl.forgotPassword, {
        method: "POST",
        body: JSON.stringify({
          email: values.email,
        }),
        headers: {
          "content-type": "application/json"
        }
      })
    },
    sendResetPasswordLinkOutsideTenantContext: async function(values) {
      return await apiFetch(authUrl.forgotPasswordNotTenant, {
        method: 'POST',
        body: JSON.stringify({
          email: values.email,
        })
      });
    },
    getTenantUserFromTokenAndEmail: async function(token, email) {
      return await apiFetch(buildUrl(authUrl.tenantUser, { token }), {
        method: "POST",
        body: {
          email
        }
      })
    },
    resetAuthCookie: async function() {
      const config = useSanctumConfig();
      const xcsrfCookie = useCookie(config.csrf.cookieKey);
      xcsrfCookie.value = null;

    },
    resetConnectedUserData: async function() {
      const userPermissions = usePermissions()
      const userRoles = useRoles()
      userPermissions.value = [];
      userRoles.value = [];
    },
    logout: async function() { await logout() }

  }
}
